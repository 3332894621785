import { theme } from "@project/shared"
import styled from "styled-components"

export const Container = styled.div`
  background: ${theme.bgColor};
  padding: 40px;
  padding-top: 0;
  padding-bottom: 100px;
  @media (max-width: 768px) {
    padding: 10px;
    button {
      white-space: pre-wrap;
      word-break: break-all;
    }
  }
`
export const StyledForm = styled.form`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  width: 100%;
  .inner-grid-content {
    width: 100%;
  }
  .grid-header {
    width: 300px;
    padding: 28px 55px 28px 18px;
  }
  .label-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  input,
  textarea {
    background-color: #ffffff;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    flex-wrap: nowrap;
    .grid-header {
      width: 100%;
      padding: 10px;
    }
  }

  .text-required {
    color: #e00;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 7px;
  }
  .text-link {
    color: #0782c8;
    text-decoration: underline;
    display: inline-flex;
    margin-right: 15px;
  }
  .text-field-headertext {
    margin-top: 20px;
  }

  .button-wrapper {
    margin-top: 30px;
    display: flex;
    .cancel-btn {
      margin-right: 17px;
    }
    @media (max-width: 767px) {
      display: flex;
      align-items: center;
      justify-content: center;
      .cancel-btn {
        margin-right: 10px;
      }
    }
    @media (max-width: ${theme.breakpoints.md}) {
      &.md_mt-0 {
        margin-top: 0px;
      }
    }
    &.sbn_button-wrapper {
      @media (max-width: 767px) {
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-top: 20px;
        row-gap: 10px;
        .cancel-btn {
          margin-right: 10px;
        }
      }
      @media (max-width: 388px) {
        button {
          padding-inline: 20px;
        }
      }
    }
  }

  .ant-radio.ant-radio-checked .ant-radio-inner::after {
    background-color: #0782c8;
  }
  .title {
    white-space: pre-wrap;
    word-break: break-all;
  }
`
export const Info = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444748;
  margin-top: 9px;
`
export const DateField = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 619px) {
    flex-wrap: wrap;
  }
`
export const FormTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
`
export const TitleName = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  width: 100%;
  text-align: center;
  @media screen and (max-width: 619px) {
    text-align: start;
  }
`
export const Divisor = styled.div`
  border-top: 1px solid #d2d1d1;
  margin-bottom: 20px;
  margin-top: 12px;
  width: 100%;
  @media print {
    border-top: unset;
  }
`
export const HorizontalInfo = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  .info {
    margin-left: 21px;
    margin-top: 0;
  }
  input {
    width: 240px;
  }
  @media (max-width: 1050px) {
    flex-direction: column;
    align-items: flex-start;
    .info {
      margin-left: 0;
      margin-top: 9px;
    }
    .half-field {
      width: 100%;
    }
    input {
      width: 100% !important;
    }
  }
`

/**
 * few common styling for restricted route pages
 * like login, forget-password, reset-password
 *
 *
 */

export const BodyWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background-color: #ffff;
  border-radius: 10px;
  .login-btn-inside {
    margin-bottom: 44px;
    @media (max-width: 1024px) {
      margin-bottom: 40px;
    }
  }
  .login-btn {
    width: 165px;
    height: 35px;

    @media (max-width: 1024px) {
      width: 140px;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    @media (max-width: 1024px) {
      width: 100%;
    }
  }
  @media (max-width: 1024px) {
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
    width: 100%;
    margin-bottom: 50px;
  }
`

export const SuccessText = styled.div`
  border-radius: 10px;
  background-color: white;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  padding: 1rem;
  width: 406px;
  @media (max-width: 420px) {
    width: 100%;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`

export const Containeer = styled.div`
  background: ${theme.bgColor};
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px 0px;
  flex: 1;
  .top-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
      margin: 17px 0;
      font-size: 18px;
      font-weight: 500;
    }
    .logo-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 133px;
      width: 133px;
      background: white;
      border-radius: 50%;
    }
  }
`

export const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${theme.darkGrey};
  background: ${theme.base};
  padding: 1rem 2rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
`

export const TextFieldWrapper = styled.div`
  margin-top: 35px;
  .forget-password-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 27px;
    p {
      margin: auto 0px;
      font-size: 14px;
    }
    a {
      text-decoration: underline;
      margin-left: 5px;
      font-size: 14px;
    }
  }
  .login-button-wrapper {
    text-align: center;
  }
  .password-info-text {
    font-size: 8px;
    color: ${theme.secondaryDark};
  }
`

export const InputFieldWrapper = styled.div`
  margin-bottom: 25px;
`
export const Highlighter = styled.div`
  background: #0782c8;
  width: 3px;
  height: 25px;
  margin-right: 8px;
`
export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`

export const Title = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 37px;
  color: #191919;
`
export const Detail = styled.div`
  font-size: 16px;
  color: #191919;
  margin-bottom: 30px;
`
export const TagWrapper = styled.div`
  margin-bottom: 10px;
`
export const ErrorMessage = styled.div`
  font-size: 12px;
  color: ${theme.alert};
  margin-top: 2px;
  margin-left: 2px;
`

export const DataContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  padding: 11px 30px 27px 30px;

  &.custom-table-class .ant-table .ant-table-container {
    border-color: ${theme.gray2};
    .ant-table-content table {
      border-color: ${theme.gray2};
      thead.ant-table-thead > tr > th,
      tbody.ant-table-tbody > tr > td {
        border-color: ${theme.gray2};
      }
    }
  }

  @media print {
    @page {
      margin: 1cm 0cm;
    }
    background-color: none;
    box-shadow: none;
    border-radius: none;
    padding: none;
  }

  @media screen and (max-width: ${theme.breakpoints.sm}) {
    padding: 12px;
  }
`
