import { API } from "@project/shared"

export interface IReturnServiceProvisionSheet {
  count?: number
  data?: any
}

interface IServiceProvisionSheetParam {
  page?: string
  pageSize?: string
  year: string
  month: string
  facilityId: string
  showAbsence: boolean
  examContent: string[]
}

export const fetchServiceProvisionSheet = async ({
  queryKey,
}): Promise<IReturnServiceProvisionSheet> => {
  const params = {} as IServiceProvisionSheetParam
  params.page = queryKey[1].page || "1"
  params.year = queryKey[1].year
  params.month = queryKey[1].month
  params.facilityId = queryKey[1].facilityId
  params.showAbsence = queryKey[1].showAbsence
  params.pageSize = queryKey[2] || "31"

  return API.get(`/child-parent/service-provision-record-sheet`, {
    params,
  })
}

export const fetchInfo = (query = "") => {
  return API.get(`/child-parent/me${query}`)
}

export const postDigitalSignatureImage = (data) => {
  const { id } = data
  delete data.id
  return API.patch(`/child-parent/service-provision-record-sheet/${id}`, data)
}
