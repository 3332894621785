import { API } from "@project/shared"

export const fetchChildsupport = async () => {
  const result = await API.get(`/child-parent/child-support`)
  return result
}
export const fetchChildsupportDraft = async () => {
  const result = await API.get(`/child-parent/child-support-draft`)
  return result
}
export const fetchChildMonitoringDetail = async () => {
  const result = await API.get(`/child-parent/child-monitoring`)
  return result
}
