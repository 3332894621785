import { API, removeBlankAttributes } from "@project/shared"
import moment from "moment"

export const getLatestServiceProvision = (query: any) => {
  const param: any = {}
  param.read_status = query?.queryKey[1]?.readStatus || null
  param.date_from = query?.queryKey[1]?.start
    ? moment(query?.queryKey[1]?.start).format("YYYY-MM-DD")
    : null
  param.date_to = query?.queryKey[1]?.end
    ? moment(query?.queryKey[1]?.end).format("YYYY-MM-DD")
    : null

  return API.get("/child-parent/latest-service-provision", {
    params: removeBlankAttributes(param),
  })
}

export const setMarkAsRead = (id) => {
  return API.patch(`/child-parent/mark-read-comments/${id}`)
}

export const setMarkAsUnRead = (id: number) => {
  return API.patch(`/child-parent/mark-unread-comments/${id}`)
}

export const getServiceProvisionDetail = (id: number | string) => {
  return API.get(`/child-parent/service-provision-record/${id}`)
}

export const fetchAllServiceProvisionCategories = async (): Promise<any> => {
  const params = {} as any
  params.page = 1
  params.pageSize = "Infinity"
  return API.get(`/child-parent/service-provision-content-category`, {
    params,
  })
}
