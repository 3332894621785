import { createContext } from "react"
import { useQuery } from "react-query"
import { fetchCompany } from "../services"

type ContextProps = {
  loading: boolean
  user: any | null
  authenticated: boolean
  setUser: any
  isOwner: boolean
  logOut: any
  facilities: any
  childInformation: any
  companyInfo?: any
  isInfoLoading?: boolean
}

export const AuthContext = createContext<Partial<ContextProps>>({})

type AuthProviderProps = {
  loading: boolean
  user: any | null
  setUser: any
  isOwner: boolean
  children: React.ReactNode
  logOut: any
  facilities: any
  childInformation: any
  isInfoLoading?: boolean
}

export const AuthProvider = (props: AuthProviderProps) => {
  const {
    loading,
    user,
    setUser,
    children,
    isOwner,
    logOut,
    facilities,
    childInformation,
    isInfoLoading,
  } = props

  const { data: companyInfo } = useQuery(
    ["fetch-company-info"],
    () => fetchCompany(),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: 1,
      cacheTime: 0,
      enabled: childInformation?.my_page_setting?.footer_company_info_flg
        ? true
        : false,
      select: ({ data }) => {
        return {
          company_name: data?.company_name,
          fax_no: data?.fax_no,
          tel_no: data?.tel_no,
          zip_code: data?.zip_code,
          address: data?.address,
        }
      },
    }
  )

  return (
    <AuthContext.Provider
      value={{
        loading,
        user,
        authenticated: user !== null,
        setUser,
        isOwner,
        logOut,
        facilities,
        childInformation,
        companyInfo,
        isInfoLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
