import { API } from "@project/shared"

export interface IReturnParentContact {
  count?: number
  data?: any
}

interface IParentContactParam {
  page?: string
  pageSize?: string
  displayContent: string
  parentId: string
  childId: string
  facilityIds: string
  readStatus: string
  dateOfContact: string
  keyword: string
}

interface IChildCommentParam {
  page?: string
  pageSize?: string
  parentId?: string
  childId: string
  facilityIds?: string
  readStatus?: string
  sort?: string
}

export const fetchParentContact = async ({
  queryKey,
}): Promise<IReturnParentContact> => {
  const params = {} as IParentContactParam
  params.page = queryKey[1]
  params.pageSize = queryKey[3] || "20"
  params.displayContent = queryKey[2]?.displayContent
  params.parentId = queryKey[2]?.parentId
  params.childId = queryKey[2]?.childId
  params.facilityIds = queryKey[2]?.facilityIds
  params.readStatus = queryKey[2]?.status
  params.dateOfContact = queryKey[2]?.dateOfContact
  params.keyword = queryKey[2]?.keyword
  return API.get(`/parent-contacts`, {
    params,
  })
}

export const addChat = async (values) => {
  return API.post(`/child-parent/comments`, values)
}

export const fetchChildChat = async ({ queryKey }): Promise<any> => {
  const params = {} as IChildCommentParam
  params.page = queryKey[1] || 1
  params.pageSize = queryKey[3] || "Infinity"
  params.parentId = queryKey[2]?.parentId
  params.childId = queryKey[2]?.childId
  params.facilityIds = queryKey[2]?.facilityIds
  params.sort = "first"

  return API.get(`/child-parent/comments`, {
    params,
  })
}

export const deleteComment = async (id): Promise<IReturnParentContact> => {
  return API.delete(`/child-parent/comments/${id}`)
}
