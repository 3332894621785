import { API } from "@project/shared"

const BASE_URL = "/parent_self_assessments"

export const createParentAnswers = (values: any) => {
  const { facilityID, parentID, year } = values
  return API.post(
    `${BASE_URL}/${facilityID}/answers/${parentID}/${year}`,
    values
  )
}

export const getParentAnswers = (
  parentID: number | string,
  year: number | string,
  facilityID: number | string
) => API.get(`${BASE_URL}/${facilityID}/answers/${parentID}/${year}`)
