import { API } from "@project/shared"
import { openAPI } from "@project/shared/src/api"

interface IEmailParam {
  email?: string
  mail_receive_notification_enter_flag?: boolean
  mail_receive_notification_leave_flag?: boolean
}

interface IChildParam {
  my_page_login_id: string
  email: string
}

export const updateChildEmail = async (values: IEmailParam) => {
  return API.patch(`/child-parent/email-notification-settings`, values)
}

export const getChildEmail = async () => {
  return API.get(`/child-parent/me`)
}

export const sendCustomPasswordResetEmail = async (values: IChildParam) => {
  return openAPI.post(`/child-user/reset`, values)
}
