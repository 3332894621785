import { createGlobalStyle } from "styled-components"

const GlobalStyles: any = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 16px;
    font-family: "Noto Sans JP", serif;
  }

  button {
    cursor: pointer;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  div .ant-popover{
    width: 67%;
    max-width: 325px;
  }
.service__record_sheet_modal{
  .ant-modal-content{
    .ant-modal-close{
      span{
          color:#FFF;
      }
    }
    .ant-modal-header{
      background: #06BCD4 !important;
      .ant-modal-title{
        color:#FFF;
      }
    }
  }
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
    margin-bottom: 4px;
    margin-left: 27px;
    font-size: 16px;
}
`

export { GlobalStyles }
