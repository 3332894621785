import { API } from "@project/shared"

// Services for child-parent endpoint (fetch self child details)

const BASE_URL = "/child-parent"

export const getSelfChildDetail = async () => API.get(`${BASE_URL}/me`)

export const getParentChildEvaluations = async (year) =>
  API.get(`/parent-self-assessments/published/${year}`)

export const getSelfChildContractStatus = (firebaseUid) => {
  return API.get(`/child-parent/contract-status/${firebaseUid}`)
}
