import { API } from "@project/shared"

export interface IReturnChild {
  count?: number
  data?: any
}

interface IChildParam {
  month?: any
  category_id?: string
  page?: number
  pageSize?: number
  id?: number
}

export const fetchProgramRecord = async ({
  queryKey,
}): Promise<IReturnChild> => {
  const params = {} as IChildParam
  params.category_id = queryKey[1]?.category_id
  params.month = queryKey[1]?.month
  params.page = queryKey[1]?.page
  params.pageSize = queryKey[1]?.pageSize
  return API.get(
    `/program-record?page=${params.page}&pageSize=${params.pageSize}`,
    {
      params,
    }
  )
}

export const fetchChildProgramRecord = async ({
  queryKey,
}): Promise<IReturnChild> => {
  const params = {} as IChildParam
  params.category_id = queryKey[1]?.category_id
  params.month = queryKey[1]?.month
  params.page = queryKey[1]?.page
  params.pageSize = queryKey[1]?.pageSize
  return API.get(
    `/child-parent/program-record?page=${params.page}&pageSize=${params.pageSize}`,
    {
      params,
    }
  )
}

export const fetchChildProgramRecordDetails = async ({
  queryKey,
}): Promise<IReturnChild> => {
  const params = {} as IChildParam
  params.id = queryKey[1]?.id
  return API.get(`/child-parent/program-record/${params.id}`)
}
